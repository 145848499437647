import { FC } from "react";
import { styled, Box, useTheme } from "@mui/material/";

import LogoContainer from "./components/LogoContainer";
import NavMenueContainer from "./components/NavMenueContainer";
import LogoComponent from "../Logo/LogoComponent";

const StyledNavbarContainer = styled(Box)(
  ({ bgcolor, shadow }: { bgcolor?: string; shadow?: boolean }) => ({
    zIndex: 100,
    position: "absolute",
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    top: 0,
    right: 0,
    width: "100vw",
    padding: "1",
    height: "50px",
    backgroundColor: bgcolor || "#0000000",
    boxShadow: shadow ? "1px 1px 1px -1px #000000" : null,
  })
);

interface INavBar {
  title?: string;
  height?: number;
  logo?: boolean;
  bgColor?: string;
  leftContainer?: React.ReactNode;
  centerContainer?: React.ReactNode;
  rightContainer?: React.ReactNode;
}
const NavBar: FC<INavBar> = (props) => {
  const {
    height,
    logo,
    title,
    bgColor,
    leftContainer,
    rightContainer,
    centerContainer,
  } = props;

  const theme = useTheme();

  return (
    <StyledNavbarContainer bgcolor={bgColor} style={{ height: height || null }}>
      {leftContainer || logo ? (
        <LogoContainer>
          <LogoComponent color={theme.palette.ikkyo_colors.one} />
        </LogoContainer>
      ) : null}
      {centerContainer || <NavMenueContainer>{title || ""}</NavMenueContainer>}

      {rightContainer}
    </StyledNavbarContainer>
  );
};

export default NavBar;
