import { FC, useState } from "react";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { Box, Button, Typography } from "@mui/material";
import Snake from "../Minigames/Snake";

interface Props {
  isOpen: boolean;
  label?: string;
  game?: boolean;
  bgcolor?: string;
}

const Loadingscreen: FC<Props> = (props) => {
  // game statet
  const [game, setGame] = useState(props.game || false);
  const label = props.label || "...Loading...";
  return (
    <div>
      <Backdrop
        sx={{
          color: "#fff",
          backgroundColor: props.bgcolor || "#0000006",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={props.isOpen}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <CircularProgress color="inherit" />
          <Box m={3}>
            <Typography>{label}</Typography>
          </Box>
          {!game && (
            <Button onClick={() => setGame(true)}>
              <Typography variant="button">play snake</Typography>
            </Button>
          )}

          {game && (
            <Snake
              color1="#248ec2"
              color2="#1d355e"
              backgroundColor="#000000"
            />
          )}
        </Box>
      </Backdrop>
    </div>
  );
};

export default Loadingscreen;
