import { FC } from "react";
import { styled, Box } from "@mui/material/";

const Container = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  //outline: "1px dashed blue",
}));

interface Props {
  children?: React.ReactNode;
}

const LogoContainer: FC<Props> = (props) => {
  return <Container>{props.children}</Container>;
};

export default LogoContainer;
