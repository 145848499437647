import { FC, ReactNode, useEffect, useRef, useState } from "react";
import { styled, Box, Tooltip } from "@mui/material/";
import { IkkyoScenesIcon } from "../../../icons";
import { gsap } from "gsap";
import { useTranslation } from "react-i18next";

interface DrawerProps {
  children?: ReactNode;
  onHandleClick?: () => void;
  onAnimateComplete?: () => void;
  stiselYPosition?: number;
  stiselColor?: string;
  drawerWidth?: number;
  isOpen?: boolean;
}
interface StiselProps {
  color?: string;
}

const Drawer: FC<DrawerProps> = (props) => {
  const {
    children,
    onHandleClick,
    onAnimateComplete,
    stiselYPosition,
    drawerWidth,
    isOpen,
  } = props;
  const [animate, setAnimate] = useState(isOpen || false);

  const containerRef = useRef();

  const { t } = useTranslation();

  useEffect(() => {
    // Update the animate state based on the isOpen prop
    setAnimate(isOpen || false);
    // Animate the drawer when isOpen changes
    gsap.to(containerRef.current, {
      transform: isOpen ? "translateX(0px)" : `translateX(-${drawerWidth}px)`,
    });
  }, [isOpen, drawerWidth]);

  const moveDrawerProperty = {
    transform: animate ? `translateX(-${drawerWidth}px)` : "translateX(0px)",
    onComplete: () => onAnimateComplete && onAnimateComplete(),
  };

  const handleClick = () => {
    onHandleClick && onHandleClick();
    // Toggle the drawer manually if not controlled from outside
    const newAnimateState = !animate;
    setAnimate(newAnimateState);
    gsap.to(containerRef.current, moveDrawerProperty);
  };

  return (
    <Container ref={containerRef} width={drawerWidth || 300}>
      {children && (
        <Tooltip
          arrow
          title={t("Scenes List")}
          placement="right"
          enterDelay={1000}
        >
          <Stisel
            color={props.stiselColor}
            onClick={handleClick}
            sx={{
              transform: `translate(31px,${stiselYPosition || 0}px)`,
            }}
          >
            <IkkyoScenesIcon
              sx={{
                color: "ikkyo_colors.gray",
              }}
            />
          </Stisel>
        </Tooltip>
      )}
      <ContentContainer>{children}</ContentContainer>
    </Container>
  );
};

const Container = styled(Box)(({ theme }) => ({
  zIndex: 2,
  position: "absolute",
  left: "0px",
  top: "0px",
  height: "100%",
}));

const ContentContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  height: "100%",
  width: "100%",
}));

const Stisel = styled("div")<StiselProps>(({ theme, color }) => ({
  zIndex: 2,
  position: "absolute",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  outlineStyle: "solid",
  outlineWidth: "1px",
  outlineColor: theme.palette.ikkyo_colors.gray,

  right: "0px",
  top: "50%",
  width: "30px",
  height: "100px",

  borderRadius: "0px 5px 5px 0px",
  backgroundColor: color || theme.palette.ikkyo_colors.transparent2,

  // hover effect
  "&:hover": {
    backgroundColor: theme.palette.ikkyo_colors.one,
    cursor: "pointer",
    transition: "background-color 0.3s ease", // This line adds the fade effect
  },
}));
export default Drawer;
