import { FC } from "react";
import { Box, styled } from "@mui/material/";
import { getRandomColor } from "../../utils/colorHelpers";

interface LogoComponentProps {
  color?: string;
  bg_color?: string;
  width?: string;
  height?: string;
  onClick?: () => void;
}

interface ContainerProps {
  width?: string;
  height?: string;
  bg_color?: string;
  children?: React.ReactNode;
}

const Container = styled(Box)(
  ({ bg_color, height, width }: ContainerProps) => ({
    height: height || "50px",
    width: width || "50px",
    backgroundColor: bg_color || "",
  })
);

const LogoComponent: FC<LogoComponentProps> = (props) => {
  const { color, width, height, bg_color, children, onClick } = props;

  const customColor = color || getRandomColor();

  return (
    <Container
      width={width}
      height={height}
      bg_color={bg_color}
      onClick={onClick}
    >
      <svg
        width={"100%"}
        height={"100%"}
        viewBox={`0 0 ${523} ${523}`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M194 346V452" stroke={customColor} strokeWidth="10" />
        <path
          d="M290 101.5V305L404.5 374.5"
          stroke={customColor}
          strokeWidth="10"
        />
        <path d="M290 302L112 346" stroke={customColor} strokeWidth="10" />
        <path d="M309 152L444 133" stroke={customColor} strokeWidth="10" />
        <path d="M192 170L194 311" stroke={customColor} strokeWidth="10" />
        <path
          d="M88 118L192.5 170L271 158.742"
          stroke={customColor}
          strokeWidth="10"
        />
      </svg>
      {children}
    </Container>
  );
};

export default LogoComponent;
