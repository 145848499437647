import jwt_decode from "jwt-decode";
import { USER_AUTH_TOKEN_NAME } from "../config";
import { DecryptedBearerToken } from "../interfaces";
import Cookie from "universal-cookie";

export function setRawToken(value: string) {
  if (undefined === value) return;
  try {
    const cookie = new Cookie();
    cookie.set(USER_AUTH_TOKEN_NAME, value, {
      maxAge: 60 * 60 * 24 * 30,
      path: "/",
    });
  } catch (error) {
    return "not possible to set token";
  }
}

export function getRawToken() {
  try {
    const cookie = new Cookie();
    const token = cookie.get(USER_AUTH_TOKEN_NAME);
    if (token) {
      return token;
    }
  } catch (error) {
    return "no token found";
  }
}

export function getAuthToken() {
  let token = getRawToken();

  if (!token) return;

  const decodedToken: DecryptedBearerToken = jwt_decode(token);
  return decodedToken;
}

export function getUserFromAuthToken(token: string) {
  if (null === token) return;
  setRawToken(token);
  const decodedToken: DecryptedBearerToken = jwt_decode(token);
  return decodedToken;
}

export function clearAuthToken() {
  let authToken = localStorage.getItem(USER_AUTH_TOKEN_NAME);
  if (authToken) {
    localStorage.clear();
  }
}
