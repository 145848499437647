import { useEffect, useState, FC } from "react";
import { Route, Redirect } from "react-router-dom";
import { getAuthToken } from "../utils/authToken";

const PrivateRoute: FC<any> = ({ component: Component, ...rest }) => {
  const [isAuthenticated, setIsAuthenticated] = useState<null | boolean>(null);

  useEffect(() => {
    try {
      let token = getAuthToken();

      if (token) {
        let tokenExpiration = token.exp;

        let dateNow = new Date().getTime() / 1000;

        if (tokenExpiration < dateNow) {
          console.log("token expired");
          setIsAuthenticated(false);
        } else {
          setIsAuthenticated(true);
        }
      } else {
        console.log("please login first");
        setIsAuthenticated(false);
      }
    } catch (err) {
      setIsAuthenticated(false);
      console.log(err);
    }
  }, []);

  if (isAuthenticated === null) {
    return <div></div>;
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        !isAuthenticated ? <Redirect to={"/"} /> : <Component {...props} />
      }
    />
  );
};

export default PrivateRoute;
