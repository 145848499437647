import { FC } from "react";
import { Box, Typography, styled } from "@mui/material/";
import { MessageTypes } from "../../interfaces";
import itworks from "../../assets/img/itWorks.jpg";
import { ErrorIcon, InfoIcon } from "../../icons";

const StyledContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.ikkyo_colors.two,
  padding: theme.spacing(1),
}));

const StyledContent = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  backgroundColor: theme.palette.ikkyo_colors.two,
  outline: "1px dashed",
  outlineColor: theme.palette.ikkyo_colors.one,
  userSelect: "text",
  color: theme.palette.ikkyo_colors.one,
  padding: theme.spacing(1),
}));

interface Props {
  msg: string;
  type?: MessageTypes;
}

const InfoMessage: FC<Props> = (props) => {
  const { msg, type } = props;

  if (msg === "Failed to fetch") {
    return (
      <Box width={1} position="relative">
        <img
          src={itworks}
          alt="sorry"
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            position: "absolute",
          }}
        />
      </Box>
    );
  }

  return (
    <StyledContainer>
      <StyledContent>
        <Typography
          marginRight={1}
          variant="h6"
          color={type === MessageTypes.ErrorMsg ? "coral" : "inherit"}
        >
          {type === MessageTypes.ErrorMsg ? (
            <ErrorIcon color="error" />
          ) : (
            <InfoIcon color="warning" />
          )}
        </Typography>
        <Typography
          variant="body2"
          color={type === MessageTypes.ErrorMsg ? "coral" : "inherit"}
        >
          {msg || null}
        </Typography>
      </StyledContent>
    </StyledContainer>
  );
};

export default InfoMessage;
