import { ListItemIcon, MenuItem, Typography } from "@mui/material";
import React, { Fragment } from "react";
import { useHistory } from "react-router-dom";
import { ArrowRightIcon } from "../../icons";
import { useJumpTo } from "../../plumbing/hooks/useJumpTo";
import { ROUTE } from "../../routes";

const RouterButtons: React.FC<any> = () => {
  const got2Route = useJumpTo();

  const history = useHistory();

  const availableRoutes = [
    ROUTE.DASHBOARD,
    ROUTE.EDITOR,
    ROUTE.PHOBIA,
    ROUTE.VICI,
  ];

  const checkForRoute = (route: any) => {
    return availableRoutes.includes(route);
  };
  const filteredRoutes = availableRoutes.filter(checkForRoute);

  const routeButtons = () => {
    return filteredRoutes.map((route, i) => {
      //exclude current path from menue
      if (history.location.pathname !== route.path) {
        return (
          <MenuItem
            key={i}
            onClick={() => {
              got2Route(route);
            }}
          >
            <ListItemIcon>
              <ArrowRightIcon fontSize="small" />
            </ListItemIcon>
            <Typography variant="body1">{`${route.label}`}</Typography>
          </MenuItem>
        );
      }
      return null;
    });
  };

  return <Fragment>{routeButtons()}</Fragment>;
};

export default RouterButtons;
