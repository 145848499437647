import { useHistory } from "react-router-dom";
import { RouteObject, User } from "../../interfaces";

export const useJumpTo = () => {
  let history = useHistory();
  const pageJump = (route: RouteObject) => {
    if (route) {
      history.push(route.path);
    } else {
      console.error("no route provided");
    }
  };

  return pageJump;
};

export const checkAccess = (user: User, route: RouteObject) => {
  if (route.access) {
    if (route.access.includes(user.role)) {
      return true;
    } else {
      return false;
    }
  } else {
    return true;
  }
};
