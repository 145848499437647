import { ApolloClient, InMemoryCache, HttpLink } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import config from "./config";
import { getRawToken } from "./utils/authToken";

const httpUri = config.url;

const httpLink = new HttpLink({
  uri: httpUri,
});

const authLink = setContext((_, { headers }) => {
  const token = getRawToken();

  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const inMemoryCache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        getMyTours: {
          merge(existing, incoming) {
            return incoming;
          },
        },
        scenesByTourID: {
          merge(existing, incoming) {
            return incoming;
          },
        },
        tours: {
          merge(existing, incoming) {
            return incoming;
          },
        },
      },
    },
  },
});

const apolloClient = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: inMemoryCache,
});

export default apolloClient;
