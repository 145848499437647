import { FC } from "react";
import { styled, Box } from "@mui/material/";

const Container = styled(Box)(({ theme }) => ({
  flexBasis: 1,
  flexGrow: 2,
}));

interface Props {
  children?: React.ReactNode;
}

const NavMenueContainer: FC<Props> = (props) => {
  return <Container>{props.children}</Container>;
};

export default NavMenueContainer;
