export const USER_AUTH_TOKEN_NAME =
  process.env.REACT_APP_AUTH_TOKEN_NAME || "token";

const ORIGIN = process.env.REACT_APP_SERVER_URL || "127.0.0.1:4000";
const UPLOAD = process.env.REACT_APP_UPLOAD || "127.0.0.1:4000";
const ENDPOINT = process.env.REACT_APP_ENDPOINT || "graphql";
const PROTOCOL = process.env.REACT_APP_PROTOCOL || "http";

const config = {
  authTokenName: USER_AUTH_TOKEN_NAME,
  url: `${PROTOCOL}://${ORIGIN}/${ENDPOINT}`,
  assets: `${PROTOCOL}://${ORIGIN}/download/assets`,
  upload: `${PROTOCOL}://${UPLOAD}/api/upload`,
  download: `${PROTOCOL}://${UPLOAD}/download`,
  baseURL: `${PROTOCOL}://${ORIGIN}`,
};

export default config;
