import { FC } from "react";
import { HelmetProvider } from "react-helmet-async";

import favicon_appleTouchIcon from "../../assets/favicon/apple-touch-icon.png";
import favicon_16 from "../../assets/favicon/favicon-16x16.png";
import favicon_32 from "../../assets/favicon/favicon-32x32.png";
import favicon_safariPinnedTab from "../../assets/favicon/safari-pinned-tab.svg";

const CustomMetaData: FC = () => {
  return (
    <HelmetProvider>
      <title>{"Ikkyo"}</title>
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href={favicon_appleTouchIcon}
      />
      <link rel="icon" type="image/png" sizes="32x32" href={favicon_32} />
      <link rel="icon" type="image/png" sizes="16x16" href={favicon_16} />
      <link rel="mask-icon" href={favicon_safariPinnedTab} color="#5bbad5" />
      <link rel="manifest" href="%PUBLIC_URL%/manifest-ean.json" />
    </HelmetProvider>
  );
};

export default CustomMetaData;
