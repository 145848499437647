import { createContext, FC, useContext, useEffect, useState } from "react";
import { User } from "../../interfaces";
import { getRawToken, getUserFromAuthToken } from "../../utils/authToken";

interface IProps {
  children?: React.ReactNode;
}

type IUserContext = {
  user: User;
  setUser: (data: User) => void;
};

const defaultUserContext = {
  user: { public_id: "", name: "" },
  setUser: () => {},
};

const UserContext = createContext<IUserContext>(defaultUserContext);

const useUserContext = () => useContext(UserContext);

const UserContextProvider: FC<IProps> = (props) => {
  const [user, setUser] = useState({ public_id: "", name: "" });

  useEffect(() => {
    const token = getRawToken() || "";

    if (!token) return;

    const userFromToken = getUserFromAuthToken(token);
    if (undefined === userFromToken) return;

    if ("" === user.public_id) {
      setUser({ public_id: userFromToken.public_id, name: "" });
    }
  }, [setUser, user.public_id]);

  return (
    <UserContext.Provider value={{ user, setUser }}>
      {props.children}
    </UserContext.Provider>
  );
};

export { UserContextProvider, UserContext, useUserContext };
