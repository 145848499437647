import { SvgIcon } from "@mui/material";

export function VRIcon(props) {
  return (
    <SvgIcon {...props}>
      <path
        d="M 21.002058,5.8147289 H 2.8965968 c -0.5554227,0 -1.0058589,0.4836232 -1.0058589,1.0799682 V 17.694379 c 0,0.596345 0.4504362,1.079969 1.0058589,1.079969 h 5.03621 c 0.7917993,0 1.5097315,-0.498474 1.8344353,-1.273688 l 0.8719539,-2.08029 c 0.248007,-0.591956 0.755337,-0.965895 1.310131,-0.965895 0.554794,0 1.062125,0.373939 1.310132,0.965895 l 0.871953,2.08029 c 0.324704,0.775214 1.042951,1.273688 1.834436,1.273688 h 5.03621 c 0.555423,0 1.005859,-0.483624 1.005859,-1.079969 V 6.8946971 c 0,-0.596345 -0.450436,-1.0799682 -1.005859,-1.0799682 z M 6.9200326,13.91449 c -1.1111599,0 -2.0117179,-0.966908 -2.0117179,-2.159936 0,-1.193028 0.900558,-2.1599366 2.0117179,-2.1599366 1.1111598,0 2.0117177,0.9669086 2.0117177,2.1599366 0,1.193028 -0.9005579,2.159936 -2.0117177,2.159936 z m 10.0585894,0 c -1.11116,0 -2.011718,-0.966908 -2.011718,-2.159936 0,-1.193028 0.900558,-2.1599366 2.011718,-2.1599366 1.11116,0 2.011717,0.9669086 2.011717,2.1599366 0,1.193028 -0.900557,2.159936 -2.011717,2.159936 z"
        id="path10"
      />
    </SvgIcon>
  );
}
