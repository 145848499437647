import { FC } from "react";
import { styled, Theme, Box } from "@mui/material/";

const StyledContainer = styled(Box)(
  ({ theme, color }: { theme?: Theme; color?: string }) => ({
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignContent: "center",
    height: "100vh",
    overflow: "hidden",
    backgroundColor: color || theme?.palette.ikkyo_colors.main,
  })
);

interface Props {
  color?: string;
  children?: React.ReactNode;
}

const PageContainer: FC<Props> = (props) => {
  const { children } = props;
  return <StyledContainer color={props.color}>{children}</StyledContainer>;
};

export default PageContainer;
