import { FC, useState } from "react";
import { TypeAnimation } from "react-type-animation";

import { styled, Typography } from "@mui/material/";

const CompanyNameText = styled(Typography)`
  font-family: Ubuntu, sans-serif;
  user-select: none;
`;

const Subtext = styled(Typography)`
  font-family: Ubuntu-Light, sans-serif;
  user-select: none;
`;

interface Props {
  text?: string;
  color?: string;
  backgroundColor?: string;
  isMobile?: boolean;
}

const CompanyName: FC<Props> = (props) => {
  const { color, text, backgroundColor, isMobile } = props;

  // subtext state
  const [subtextVisible, setSubtextVisible] = useState(false);

  return (
    <>
      <CompanyNameText
        zIndex={1}
        variant="h1"
        align="center"
        color={color}
        bgcolor={backgroundColor || ""}
        fontSize={isMobile ? "12vw" : "5vw"}
      >
        <TypeAnimation
          sequence={[
            text || "Ikkyo-Solutions",
            2000,
            () => {
              setSubtextVisible(true);
            },
          ]}
          wrapper="span"
          cursor={false}

          // style={{ fontSize: "2em", display: "inline-block" }}
        />
      </CompanyNameText>
      {subtextVisible && (
        <Subtext
          zIndex={1}
          marginTop={4}
          fontSize={isMobile ? "8vw" : "2vw"}
          variant="h4"
          color={color}
          align="center"
        >
          {"for "}
          <TypeAnimation
            sequence={[
              "psychology", // Types 'One'
              2000, // Waits 1s
              "cloud solutions", // Deletes 'One' and types 'Two'
              5000, // Waits 2s
              "mixed reality", // Deletes 'Two' and types 'Three'
              5000,
              // Types 'Three' without deleting 'Two'
              // () => {
              //   console.log("Sequence completed");
              // },
            ]}
            wrapper="span"
            cursor={true}
            repeat={1}
          />
        </Subtext>
      )}
    </>
  );
};

export default CompanyName;
