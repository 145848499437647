import { FC } from "react";
import { Box, Typography, styled } from "@mui/material/";
import { useTranslation } from "react-i18next";

const StyledContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  margin: "10px",
  outline: "2px solid",
  minHeight: "50px",
  outlineColor: theme.palette.ikkyo_colors.two,
  backgroundColor: theme.palette.ikkyo_colors.two,
}));

interface Props {
  label: string;
}

const CustomLink: FC<Props> = (props) => {
  const { label } = props;
  const { t } = useTranslation();

  return (
    <StyledContainer>
      <Typography variant="body1" color={"primary"}>
        {t(label)}
      </Typography>
    </StyledContainer>
  );
};

export default CustomLink;
