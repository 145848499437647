import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import * as PAGES from "./pages";
import { PrivateRoute, ROUTE } from "./routes";

const App: React.FC = () => (
  <BrowserRouter>
    <Switch>
      {/* public pages */}
      <Route exact path={ROUTE.HOME.path} component={PAGES.Home} />
      <Route exact path={ROUTE.ABOUT.path} component={PAGES.About} />
      <Route exact path={ROUTE.IMPRESSUM.path} component={PAGES.Impressum} />
      <Route exact path={ROUTE.LOGIN.path} component={PAGES.Login} />

      <Route
        exact
        path={`${ROUTE.TOUR.path}/:version/:tourID`}
        component={PAGES.Tour}
      />

      <Route exact path={`${ROUTE.SURVEY.path}`} component={PAGES.Survey} />

      {/* private pages */}
      <PrivateRoute exact path={ROUTE.TEST.path} component={PAGES.Test} />
      <PrivateRoute
        exact
        path={ROUTE.SETTINGS.path}
        component={PAGES.Settings}
      />
      <PrivateRoute
        exact
        path={ROUTE.DASHBOARD.path}
        component={PAGES.Dashboard}
      />
      <PrivateRoute
        exact
        path={`${ROUTE.EDITOR.path}/:version/:tourID`}
        component={PAGES.Editor}
      />

      <PrivateRoute exact path={ROUTE.PRIVATE.path} component={PAGES.Private} />
      <PrivateRoute
        exact
        path={ROUTE.STATISTICS.path}
        component={PAGES.Statistics}
      />

      <PrivateRoute
        exact
        path={`${ROUTE.PHOBIA.path}`}
        component={PAGES.Phobia}
      />
      <PrivateRoute exact path={`${ROUTE.VICI.path}`} component={PAGES.Vici} />
      <PrivateRoute exact path={`${ROUTE.VR.path}`} component={PAGES.VR} />
      {/* default page */}
      <Redirect from="*" to="/" />
    </Switch>
  </BrowserRouter>
);
export default App;
