import { IconButton, Menu, MenuItem, styled } from "@mui/material";
import React, { useState, MouseEvent } from "react";
import { DeleteIcon, EditIcon, MoreVertIcon } from "../../icons";

const StyledMenueContainer = styled("div")`
  background-color: var(--primary-text);
`;

const StyledIcon = styled(IconButton)`
  color: var(--tertiary-bg);
  background-color: var(--primary-text);
`;

const StyledMenueIcon = styled(MenuItem)`
  color: var(--tertiary-bg);
  padding: 5px;
`;

interface Props {
  onEdit: Function;
  onDelete?: Function;
}

const CardMenue: React.FC<Props> = (props) => {
  const { onEdit, onDelete } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOnEdit = () => {
    onEdit();
    handleClose();
  };

  const handleOnDelete = () => {
    onDelete();
    handleClose();
  };


  return (
    <StyledMenueContainer>
      <StyledIcon size="small" onClick={handleClick}>
        <MoreVertIcon />
      </StyledIcon>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <StyledMenueIcon onClick={handleOnEdit}>
          <EditIcon />
        </StyledMenueIcon>
        {onDelete ? (
          <StyledMenueIcon onClick={handleOnDelete}>
            <DeleteIcon />
          </StyledMenueIcon>
        ) : null}
      </Menu>
    </StyledMenueContainer>
  );
};
export default CardMenue;
