import { FC, ReactNode, useEffect, useState } from "react";
import { Box, styled } from "@mui/material/";
import { CloseIcon } from "../../icons";
import { Hotspot } from "../../graphql/types";

interface Props {
  children?: ReactNode;
  close: Function;
  getImageFromHotspot?: Function;
}

const PopUpContainer: FC<Props> = (props) => {
  const { children, close, getImageFromHotspot } = props;

  const [hotspot, sethotspot] = useState<Hotspot>(null);

  const reset = () => {
    close(false);
  };

  useEffect(() => {
    sethotspot(getImageFromHotspot());
  }, [getImageFromHotspot, hotspot, sethotspot]);

  const image = hotspot?.image;

  if (undefined === image) return null;

  return (
    <Container>
      <Content>
        <ContenHeader>
          <CloseButton onClick={reset}>
            <CloseIcon />
          </CloseButton>
        </ContenHeader>
        {children}
        <StyledImage src={image.path} alt={image.label} />
      </Content>
    </Container>
  );
};

const ContenHeader = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "right",
  width: "100%",
  backgroundColor: theme.palette.ikkyo_colors.two,
}));

const CloseButton = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: theme.palette.ikkyo_colors.one,
  padding: "5px",
  borderRadius: "25px",
}));

const Container = styled(Box)(({ theme }) => ({
  zIndex: 99,
  position: "absolute",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",

  width: "100%",
  height: "100%",
}));

const Content = styled(Box)(({ theme }) => ({
  zIndex: 100,
  position: "relative",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",

  width: "auto",
  height: "auto",
  maxHeight: "800px",
  maxWidth: "1000px",
  padding: "10px",
  borderRadius: "15px",
  backgroundColor: theme.palette.ikkyo_colors.two,
  boxShadow: "0 4px 16px rgba(50, 50, 93, 0.11), 0 1px 15px rgba(0, 0, 0, 0.5)",
}));

const StyledImage = styled("img")(({ theme }) => ({
  objectFit: "contain",
  width: "100%",
  margin: "20px",
  maxHeight: "70vh",
}));

export default PopUpContainer;
