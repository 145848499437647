import { Box, lighten, styled, useTheme } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";

import DefaultThumb from "../../assets/img/thumb_default.png";
import { IkkyoScene } from "../../graphql/types";
import SmartDisplayOutlinedIcon from "@mui/icons-material/SmartDisplayOutlined";

const StyledImage = styled("img")`
  width: 100%;
  height: 150px;
  object-fit: cover;
`;
interface Props {
  scene: IkkyoScene;
}

const Thumbnail: React.FC<Props> = (props) => {
  const { scene } = props;
  const theme = useTheme();
  const iconColor = lighten(theme.palette.ikkyo_colors.two, 0.2);
  const [loaded, setLoaded] = useState(false);
  const imgEl = useRef<HTMLImageElement>(null);

  const onImageLoaded = () => setLoaded(true);

  //BUG: on upload image does not show
  useEffect(() => {
    const imgElCurrent = imgEl.current;

    if (imgElCurrent) {
      imgElCurrent.addEventListener("load", onImageLoaded);
      return () => imgElCurrent.removeEventListener("load", onImageLoaded);
    }
  }, [imgEl]);

  let file = DefaultThumb;
  switch (scene.scene_type) {
    case "VIDEO":
      file = scene.video?.thumbnail || DefaultThumb;
      break;
    case "IMAGE":
      file = scene.image?.thumbnail || DefaultThumb;
      break;
    default:
      file = DefaultThumb;
  }

  return (
    <>
      {scene.scene_type === "VIDEO" && !scene?.video?.thumbnail ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: 150,
            width: "100%",
          }}
          bgcolor={theme.palette.ikkyo_colors.main}
        >
          <SmartDisplayOutlinedIcon
            sx={{
              fontSize: 100,
              color: iconColor,
            }}
          />
        </Box>
      ) : (
        <>
          {/* <Skeleton
            variant="rectangular"
            animation="pulse"
            style={!loaded ? { display: "block" } : { display: "none" }}
            width={"100%"}
            height={150}
            sx={{ bgcolor: darken(theme.palette.ikkyo_colors.one, 0.3) }}
          /> */}

          <StyledImage
            ref={imgEl}
            src={file}
            alt="Thumbnail"
            style={loaded ? { display: "inline-block" } : { display: "none" }}
          />
        </>
      )}
    </>
  );
};

export default Thumbnail;
