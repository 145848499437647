import { UserRoles } from "../interfaces";

export interface CustomRoute {
  path: string;
  label: string;
  access: UserRoles[];
}

export const ROUTE = {
  HOME: { path: "/", label: "home", access: [UserRoles.ALL] },
  LOGIN: { path: "/login", label: "login", access: [UserRoles.ALL] },
  PRIVATE: { path: "/private", label: "private", access: [UserRoles.ADMIN] },
  ABOUT: {
    path: "/about",
    label: "about",
    access: [UserRoles.ALL],
  },
  IMPRESSUM: {
    path: "/impressum",
    label: "impressum",
    access: [UserRoles.ALL],
  },
  REGISTER: {
    path: "/register",
    label: "register",
    access: [UserRoles.ALL],
  },
  DASHBOARD: {
    path: "/dashboard",
    label: "dashboard",
    access: [UserRoles.ALL],
  },
  EDITOR: {
    path: "/editor",
    label: "editor",
    access: [UserRoles.ALL],
  },
  TOUR: {
    path: "/tour",
    label: "tour",
    access: [UserRoles.ALL],
  },
  STATISTICS: {
    path: "/statistics",
    label: "statistics",
    access: [UserRoles.ALL],
  },
  PHOBIA: {
    path: "/phobia",
    label: "phobia",
    access: [UserRoles.ALL],
  },
  VR: {
    path: "/vr",
    label: "vr",
    access: [UserRoles.ALL],
  },
  VICI: {
    path: "/vici",
    label: "vici-test",
    access: [UserRoles.ALL],
  },
  SURVEY: {
    path: "/fragebogen",
    label: "survey",
    access: [UserRoles.ALL],
  },
  TEST: {
    path: "/test",
    label: "test",
    access: [UserRoles.ALL],
  },
  SETTINGS: {
    path: "/settings",
    label: "settings",
    access: [UserRoles.ALL],
  },
};

export const HOME_ROUTES = [
  ROUTE.HOME,
  ROUTE.LOGIN,
  ROUTE.SURVEY,
  ROUTE.SETTINGS,
  ROUTE.DASHBOARD,
];

export const HOME_ROUTES_V1 = [ROUTE.LOGIN];
