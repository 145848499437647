import { Vector3 } from "@babylonjs/core";

export enum UserRoles {
  ALL,
  ADMIN,
  USER_LEVEL_1,
  USER_LEVEL_2,
}

export type User = {
  public_id: string;
  name: string;
  role?: UserRoles;
};

export type TourURLParams = {
  tourID: string;
  version: string;
};
export interface RouteObject {
  label: string;
  path: string;
  access: UserRoles[];
}

export interface DecryptedBearerToken {
  public_id: string;
  iat: number;
  exp: number;
}

export enum MessageTypes {
  ErrorMsg,
  InfoMsg,
}

// Editor types
export interface IPointerInfo {
  camRotation: Vector3;
  viewMarkerPos: Vector3;
}

export interface ICursorPosition {
  pointerX: number;
  pointerY: number;
}

export type InputPortalHotspot = {
  spot_id: string;
  scene_id?: string;
  viewRotationX?: number;
  viewRotationY?: number;
  viewRotationZ?: number;
};
