import { lazy } from "react";

// release v1.0
//export const Home = lazy(() => import("../pages_v.1.0/Home/HomePage"));

// development v2.0
export const Home = lazy(() => import("./Home/HomePage"));
export const About = lazy(() => import("./About/AboutPage"));
export const Contact = lazy(() => import("./Contact/ContactPage"));
export const Private = lazy(() => import("./Private/PrivatePage"));
export const Impressum = lazy(() => import("./Impressum/ImpressumPage"));
export const Login = lazy(() => import("./Login/LoginPage"));
export const Dashboard = lazy(
  () => import("./Products/VR-Editor/Dashboard/DashBoardPage")
);
export const Editor = lazy(
  () => import("./Products/VR-Editor/Editor/EditorPage")
);
export const Tour = lazy(() => import("./Products/VR-Tour/IkkyoTourPage"));
export const Statistics = lazy(() => import("./Statistics/StatisticsPage"));
export const Phobia = lazy(() => import("./Products/Phobia/PhobiaPage"));
export const Vici = lazy(() => import("./Products/VICI/IkkyoViciPage"));
export const VR = lazy(() => import("./Products/VR/VRPage"));
export const Survey = lazy(() => import("./Products/Survey/SurveyPage"));
export const Test = lazy(() => import("./TestPage/TestPage"));
export const Settings = lazy(() => import("./Products/Settings/SettingsPage"));
