const isColor = (strColor: string) => {
  const s = new Option().style;
  s.color = strColor;
  return s.color !== "";
};

export function getRandomColor() {
  const newColor = "#" + Math.floor(Math.random() * 16777215).toString(16);

  return isColor(newColor) ? newColor : "#fff";
  // return "#11CC5F";
}

export function getDiscoColor() {
  // const colorSet = ["#11CC5F", "#1172cc"];
  const colorSet = ["#fff"];
  const pickedColor = colorSet[Math.floor(Math.random() * colorSet.length)];

  return pickedColor;
}
