import { FC } from "react";
import { Box, CircularProgress, styled, Typography } from "@mui/material/";

const StyledContent = styled(Box)`
  z-index: 99;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
`;

interface SpinnerProps {
  text?: string;
  visible: boolean;
}

const Spinner: FC<SpinnerProps> = (props) => {
  if (false === props.visible) return null;
  return (
    <StyledContent>
      <CircularProgress color="primary" />
      {props.text ? (
        <Typography sx={{ p: 2 }} variant={"body1"}>
          {props.text}
        </Typography>
      ) : null}
    </StyledContent>
  );
};

export default Spinner;
